import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';

// ** Axios Import
import axios from '@src/service/axios';
import { toast } from 'react-toastify';

// Loader Components
import showLoader from '@components/loader';

export const getSubTopic = createAsyncThunk('subTopicSlice/getSubTopic', async () => {
    try {
        let response = await axios.get('/sub-topic', {
            headers: {
                'Content-Type': 'application/json'
            }
        });
        return Promise.resolve(response.data);
    } catch (err) {
        toast.error(err?.response?.data?.message || 'Something went wrong!');
        return Promise.reject(err);
    }
});

export const getSubTopicWithFilter = createAsyncThunk('subTopicSlice/getSubTopicWithFilter', async (query) => {
    let myStr = query;
    // replacing for % symbol
    myStr = String(myStr).replaceAll('%', '$*$');

    try {
        let response = await axios.get('/sub-topic/with-filters' + myStr, {
            headers: {
                'Content-Type': 'application/json'
            }
        });
        console.error('response', response);
        return Promise.resolve(response.data);
    } catch (err) {
        toast.error(err?.response?.data?.message || 'Something went wrong!');
        return Promise.reject(err);
    }
});

export const getSubTopicById = createAsyncThunk('subTopicSlice/getSubTopicById', async (id) => {
    try {
        showLoader(true);

        let response = await axios.get('/sub-topic/' + id, {
            headers: {
                'Content-Type': 'application/json'
            }
        });
        showLoader(false);
        return Promise.resolve(response.data);
    } catch (err) {
        showLoader(false);
        toast.error(err.response?.data?.message || 'Something went wrong!');
        return Promise.reject(err);
    }
});

export const getTopicBySubjectId = createAsyncThunk('subTopicSlice/getTopicBySubjectId', async (id) => {
    try {
        // showLoader(true);

        let response = await axios.get('/topic/subject/' + id, {
            headers: {
                'Content-Type': 'application/json'
            }
        });
        // showLoader(false);
        return Promise.resolve(response.data);
    } catch (err) {
        // showLoader(false);
        toast.error(err.response?.data?.message || 'Something went wrong!');
        return Promise.reject(err);
    }
});

export const createSubTopic = createAsyncThunk('subTopicSlice/createSubTopic', async (data, { dispatch }) => {
    try {
        // showLoader(true);
        let response = await axios.post('/sub-topic', data, {
            headers: {
                'Content-Type': 'multipart/form-data'
            },
            onUploadProgress: (progressEvent) => {
                const progress = Math.round((progressEvent.loaded / progressEvent.total) * 100);
                // this.setState({ progress });
                console.log('progress', progress);
            }
        });
        toast.success(response?.data?.message || 'Subtopic added successfully.');
        dispatch(getSubTopic());
        // showLoader(false);
        return Promise.resolve(response.data);
    } catch (err) {
        // showLoader(false);
        toast.error(err?.response?.data?.message || 'Something went wrong!');
        return Promise.reject(err);
    }
});

export const updateSubTopic = createAsyncThunk('subTopicSlice/updateSubTopic', async (data, { dispatch }) => {
    try {
        // showLoader(true);

        let response = await axios.put('/sub-topic/' + data.id, data?.data, {
            headers: {
                'Content-Type': 'multipart/form-data'
            }
        });
        toast.success(response?.data?.message || 'Subtopic updated successfully.');
        dispatch(getSubTopic());
        // showLoader(false);
        return Promise.resolve(response.data);
    } catch (err) {
        // showLoader(false);
        toast.error(err?.response?.data?.message || 'Something went wrong!');
        return Promise.reject(err);
    }
});

export const deleteSubTopic = createAsyncThunk('subTopicSlice/deleteSubTopic', async (deleteId, { dispatch }) => {
    try {
        showLoader(true);

        let response = await axios.delete('/sub-topic/' + deleteId, {
            headers: {
                'Content-Type': 'application/json'
            }
        });
        toast.success(response?.data?.message || 'Subtopic deleted successfully.');
        dispatch(getSubTopic());
        showLoader(false);
        return Promise.resolve(response.data);
    } catch (err) {
        showLoader(false);
        toast.error(err?.response?.data?.message || 'Something went wrong!');
        return Promise.reject(err);
    }
});

export const updateSubTopicStatus = createAsyncThunk('subTopicSlice/updateSubTopicStatus', async (id, { dispatch }) => {
    try {
        showLoader(true);

        let response = await axios.put('/sub-topic/status/' + id, {
            headers: {
                'Content-Type': 'application/json'
            }
        });
        toast.success(response?.data?.message || 'Status updated successfully.');
        dispatch(getSubTopic());
        showLoader(false);
        return Promise.resolve(response.data);
    } catch (err) {
        showLoader(false);
        toast.error(err?.response?.data?.message || 'Something went wrong!');
        return Promise.reject(err);
    }
});

export const reExtractSubTopic = createAsyncThunk('subTopicSlice/reExtractSubTopic', async (id, { dispatch }) => {
    try {
        showLoader(true);

        let response = await axios.get('/sub-topic/re-extract/' + id, {
            headers: {
                'Content-Type': 'application/json'
            }
        });
        toast.success(response?.data?.message || 'Subtopic updated successfully.');
        dispatch(getSubTopic());
        showLoader(false);
        return Promise.resolve(response.data);
    } catch (err) {
        showLoader(false);
        toast.error(err?.response?.data?.message || 'Something went wrong!');
        return Promise.reject(err);
    }
});

export const subTopicSlice = createSlice({
    name: 'subTopicSlice',
    initialState: {
        data: [],
        selectedData: {}
    },
    extraReducers: (builder) => {
        builder.addCase(getSubTopic.fulfilled, (state, action) => {
            state.data = action?.payload;
            return;
        });

        builder.addCase(getSubTopicById.fulfilled, (state, action) => {
            state.selectedData = action.payload;
            return;
        });
    }
});

export default subTopicSlice.reducer;
