// ** Reducers Imports
import navbar from './navbar';
import layout from './layout';
import auth from './authentication';
import permission from './permission';
import users from '../views/Setup/User/store';
import roles from '../views/Setup/RoleRights/Role/store';
import subjectSlice from '../views/TeamUnity/Subject/store';
import topicSlice from '../views/TeamUnity/Topic/store';
import subTopicSlice from '../views/TeamUnity/SubTopic/store';
import ftpMasterSlice from '../views/Master/FtpMaster/store';
import categorySlice from '../views/Master/Category/store';

const rootReducer = {
    auth,
    navbar,
    layout,
    permission,
    users,
    roles,
    subjectSlice,
    topicSlice,
    subTopicSlice,
    ftpMasterSlice,
    categorySlice
};

export default rootReducer;
