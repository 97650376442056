import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';

// ** Axios Import
import axios from '@src/service/axios';
import { toast } from 'react-toastify';

// Loader Components
import showLoader from '@components/loader';

export const getFtpMaster = createAsyncThunk('ftpMasterSlice/getFtpMaster', async () => {
    try {
        let response = await axios.get('/ftp-master', {
            headers: {
                'Content-Type': 'application/json'
            }
        });
        return Promise.resolve(response.data);
    } catch (err) {
        toast.error(err?.response?.data?.message || 'Something went wrong!');
        return Promise.reject(err);
    }
});

export const checkRemoteServerFolder = createAsyncThunk('ftpMasterSlice/checkRemoteServerFolder', async (id, { dispatch }) => {
    try {
        showLoader(true);
        let response = await axios.get('/ftp-master/check-folder/' + id, {
            headers: {
                'Content-Type': 'application/json'
            }
        });
        toast.success(response?.data?.message || 'Remote folder check successfully.');
        showLoader(false);
        dispatch(getFtpMaster());
        return Promise.resolve(response.data);
    } catch (err) {
        showLoader(false);
        toast.error(err?.response?.data?.message || 'Something went wrong!');
        return Promise.reject(err);
    }
});

export const getFtpMasterOption = createAsyncThunk('ftpMasterSlice/getFtpMasterOption', async () => {
    try {
        showLoader(true);
        let response = await axios.get('/ftp-master?option=true', {
            headers: {
                'Content-Type': 'application/json'
            }
        });
        showLoader(false);
        return Promise.resolve(response.data);
    } catch (err) {
        showLoader(false);
        toast.error(err?.response?.data?.message || 'Something went wrong!');
        return Promise.reject(err);
    }
});

export const getFtpMasterById = createAsyncThunk('ftpMasterSlice/getFtpMasterById', async (id) => {
    try {
        showLoader(true);

        let response = await axios.get('/ftp-master/' + id, {
            headers: {
                'Content-Type': 'application/json'
            }
        });
        showLoader(false);
        return Promise.resolve(response.data);
    } catch (err) {
        showLoader(false);
        toast.error(err.response?.data?.message || 'Something went wrong!');
        return Promise.reject(err);
    }
});

export const createFtpMaster = createAsyncThunk('ftpMasterSlice/createFtpMaster', async (data, { dispatch }) => {
    try {
        showLoader(true);

        let response = await axios.post('/ftp-master', data, {
            headers: {
                'Content-Type': 'application/json'
            }
        });
        toast.success(response?.data?.message || 'Ftp Master added successfully.');
        dispatch(getFtpMaster());
        showLoader(false);
        return Promise.resolve(response.data);
    } catch (err) {
        showLoader(false);
        toast.error(err?.response?.data?.message || 'Something went wrong!');
        return Promise.reject(err);
    }
});

export const updateFtpMaster = createAsyncThunk('ftpMasterSlice/updateFtpMaster', async (data, { dispatch }) => {
    try {
        showLoader(true);

        let response = await axios.put('/ftp-master/' + data.id, data?.data, {
            headers: {
                'Content-Type': 'application/json'
            }
        });
        toast.success(response?.data?.message || 'Ftp Master updated succesfully.');
        dispatch(getFtpMaster());
        showLoader(false);
        return Promise.resolve(response.data);
    } catch (err) {
        showLoader(false);
        toast.error(err?.response?.data?.message || 'Something went wrong!');
        return Promise.reject(err);
    }
});

export const deleteFtpMaster = createAsyncThunk('ftpMasterSlice/deleteFtpMaster', async (deleteId, { dispatch }) => {
    try {
        showLoader(true);

        let response = await axios.delete('/ftp-master/' + deleteId, {
            headers: {
                'Content-Type': 'application/json'
            }
        });
        toast.success(response?.data?.message || 'Ftp Master deleted successfully.');
        dispatch(getFtpMaster());
        showLoader(false);
        return Promise.resolve(response.data);
    } catch (err) {
        showLoader(false);
        toast.error(err?.response?.data?.message || 'Something went wrong!');
        return Promise.reject(err);
    }
});

export const updateFtpMasterStatus = createAsyncThunk('ftpMasterSlice/updateFtpMasterStatus', async (id, { dispatch }) => {
    try {
        showLoader(true);

        let response = await axios.put('/ftp-master/status/' + id, {
            headers: {
                'Content-Type': 'application/json'
            }
        });
        toast.success(response?.data?.message || 'Status updated successfully.');
        dispatch(getFtpMaster());
        showLoader(false);
        return Promise.resolve(response.data);
    } catch (err) {
        showLoader(false);
        toast.error(err?.response?.data?.message || 'Something went wrong!');
        return Promise.reject(err);
    }
});

export const ftpMasterSlice = createSlice({
    name: 'ftpMasterSlice',
    initialState: {
        data: [],
        option: [],
        selectedData: {}
    },
    extraReducers: (builder) => {
        builder.addCase(getFtpMaster.fulfilled, (state, action) => {
            state.data = action?.payload;
            return;
        });
        builder.addCase(getFtpMasterOption.fulfilled, (state, action) => {
            state.option = action?.payload;
            return;
        });
        builder.addCase(getFtpMasterById.fulfilled, (state, action) => {
            state.selectedData = action.payload;
            return;
        });
    }
});

export default ftpMasterSlice.reducer;
