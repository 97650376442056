import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';

// ** Axios Import
import axios from '@src/service/axios';
import { toast } from 'react-toastify';

// Loader Components
import showLoader from '@components/loader';

export const getTopic = createAsyncThunk('topicSlice/getTopic', async () => {
    try {
        let response = await axios.get('/topic', {
            headers: {
                'Content-Type': 'application/json'
            }
        });
        return Promise.resolve(response.data);
    } catch (err) {
        toast.error(err?.response?.data?.message || 'Something went wrong!');
        return Promise.reject(err);
    }
});

export const getTopicOption = createAsyncThunk('topicSlice/getTopicOption', async () => {
    try {
        showLoader(true);
        let response = await axios.get('/topic?option=true', {
            headers: {
                'Content-Type': 'application/json'
            }
        });
        showLoader(false);
        return Promise.resolve(response.data);
    } catch (err) {
        showLoader(false);
        toast.error(err?.response?.data?.message || 'Something went wrong!');
        return Promise.reject(err);
    }
});

export const getTopicById = createAsyncThunk('topicSlice/getTopicById', async (id) => {
    try {
        showLoader(true);

        let response = await axios.get('/topic/' + id, {
            headers: {
                'Content-Type': 'application/json'
            }
        });
        showLoader(false);
        return Promise.resolve(response.data);
    } catch (err) {
        showLoader(false);
        toast.error(err.response?.data?.message || 'Something went wrong!');
        return Promise.reject(err);
    }
});

export const getTopicBySubjectId = createAsyncThunk('subTopicSlice/getTopicBySubjectId', async (id) => {
    try {
        showLoader(true);

        let response = await axios.get('/topic/subject/' + id, {
            headers: {
                'Content-Type': 'application/json'
            }
        });
        showLoader(false);
        return Promise.resolve(response.data);
    } catch (err) {
        showLoader(false);
        toast.error(err.response?.data?.message || 'Something went wrong!');
        return Promise.reject(err);
    }
});

export const createTopic = createAsyncThunk('topicSlice/createTopic', async (data, { dispatch }) => {
    try {
        showLoader(true);

        let response = await axios.post('/topic', data, {
            headers: {
                'Content-Type': 'application/json'
            }
        });
        toast.success(response?.data?.message || 'Topic added successfully.');
        dispatch(getTopic());
        showLoader(false);
        return Promise.resolve(response.data);
    } catch (err) {
        showLoader(false);
        toast.error(err?.response?.data?.message || 'Something went wrong!');
        return Promise.reject(err);
    }
});

export const updateTopic = createAsyncThunk('topicSlice/updateTopic', async (data, { dispatch }) => {
    try {
        showLoader(true);

        let response = await axios.put('/topic/' + data.id, data?.data, {
            headers: {
                'Content-Type': 'application/json'
            }
        });
        toast.success(response?.data?.message || 'Topic updated succesfully.');
        dispatch(getTopic());
        showLoader(false);
        return Promise.resolve(response.data);
    } catch (err) {
        showLoader(false);
        toast.error(err?.response?.data?.message || 'Something went wrong!');
        return Promise.reject(err);
    }
});

export const deleteTopic = createAsyncThunk('topicSlice/deleteTopic', async (deleteId, { dispatch }) => {
    try {
        showLoader(true);

        let response = await axios.delete('/topic/' + deleteId, {
            headers: {
                'Content-Type': 'application/json'
            }
        });
        toast.success(response?.data?.message || 'Topic deleted successfully.');
        dispatch(getTopic());
        showLoader(false);
        return Promise.resolve(response.data);
    } catch (err) {
        showLoader(false);
        toast.error(err?.response?.data?.message || 'Something went wrong!');
        return Promise.reject(err);
    }
});

export const updateTopicStatus = createAsyncThunk('topicSlice/updateTopicStatus', async (id, { dispatch }) => {
    try {
        showLoader(true);

        let response = await axios.put('/topic/status/' + id, {
            headers: {
                'Content-Type': 'application/json'
            }
        });
        toast.success(response?.data?.message || 'Status updated successfully.');
        dispatch(getTopic());
        showLoader(false);
        return Promise.resolve(response.data);
    } catch (err) {
        showLoader(false);
        toast.error(err?.response?.data?.message || 'Something went wrong!');
        return Promise.reject(err);
    }
});

export const topicSlice = createSlice({
    name: 'topicSlice',
    initialState: {
        data: [],
        option: [],
        topicBySubjectOption: [],
        selectedData: {}
    },
    reducers: {
        removeTopicOptions: (state) => {
            state.topicBySubjectOption = [];
        }
    },
    extraReducers: (builder) => {
        builder.addCase(getTopic.fulfilled, (state, action) => {
            state.data = action?.payload;
            return;
        });
        builder.addCase(getTopicOption.fulfilled, (state, action) => {
            state.option = action?.payload;
            return;
        });
        builder.addCase(getTopicBySubjectId.fulfilled, (state, action) => {
            state.topicBySubjectOption = action?.payload;
            return;
        });
        builder.addCase(getTopicById.fulfilled, (state, action) => {
            state.selectedData = action.payload;
            return;
        });
    }
});
export const { removeTopicOptions } = topicSlice.actions;

export default topicSlice.reducer;
