import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';

// ** Axios Import
import axios from '@src/service/axios';
import { toast } from 'react-toastify';

// Loader Components
import showLoader from '@components/loader';

export const getSubject = createAsyncThunk('subjectSlice/getSubject', async () => {
    try {
        let response = await axios.get('/subject', {
            headers: {
                'Content-Type': 'application/json'
            }
        });
        return Promise.resolve(response.data);
    } catch (err) {
        toast.error(err?.response?.data?.message || 'Something went wrong!');
        return Promise.reject(err);
    }
});

export const getSubjectOption = createAsyncThunk('subjectSlice/getSubjectOption', async () => {
    try {
        showLoader(true);
        let response = await axios.get('/subject?option=true', {
            headers: {
                'Content-Type': 'application/json'
            }
        });
        showLoader(false);
        return Promise.resolve(response.data);
    } catch (err) {
        showLoader(false);
        toast.error(err?.response?.data?.message || 'Something went wrong!');
        return Promise.reject(err);
    }
});

export const getSubjectById = createAsyncThunk('subjectSlice/getSubjectById', async (id) => {
    try {
        showLoader(true);

        let response = await axios.get('/subject/' + id, {
            headers: {
                'Content-Type': 'application/json'
            }
        });
        showLoader(false);
        return Promise.resolve(response.data);
    } catch (err) {
        showLoader(false);
        toast.error(err.response?.data?.message || 'Something went wrong!');
        return Promise.reject(err);
    }
});

export const createSubject = createAsyncThunk('subjectSlice/createSubject', async (data, { dispatch }) => {
    try {
        showLoader(true);

        let response = await axios.post('/subject', data, {
            headers: {
                'Content-Type': 'application/json'
            }
        });
        toast.success(response?.data?.message || 'Subject added successfully.');
        dispatch(getSubject());
        showLoader(false);
        return Promise.resolve(response.data);
    } catch (err) {
        showLoader(false);
        toast.error(err?.response?.data?.message || 'Something went wrong!');
        return Promise.reject(err);
    }
});

export const updateSubject = createAsyncThunk('subjectSlice/updateSubject', async (data, { dispatch }) => {
    try {
        showLoader(true);

        let response = await axios.put('/subject/' + data.id, data?.data, {
            headers: {
                'Content-Type': 'application/json'
            }
        });
        toast.success(response?.data?.message || 'Subject updated succesfully.');
        dispatch(getSubject());
        showLoader(false);
        return Promise.resolve(response.data);
    } catch (err) {
        showLoader(false);
        toast.error(err?.response?.data?.message || 'Something went wrong!');
        return Promise.reject(err);
    }
});

export const deleteSubject = createAsyncThunk('subjectSlice/deleteSubject', async (deleteId, { dispatch }) => {
    try {
        showLoader(true);

        let response = await axios.delete('/subject/' + deleteId, {
            headers: {
                'Content-Type': 'application/json'
            }
        });
        toast.success(response?.data?.message || 'Subject deleted successfully.');
        dispatch(getSubject());
        showLoader(false);
        return Promise.resolve(response.data);
    } catch (err) {
        showLoader(false);
        toast.error(err?.response?.data?.message || 'Something went wrong!');
        return Promise.reject(err);
    }
});

export const updateSubjectStatus = createAsyncThunk('subjectSlice/updateSubjectStatus', async (id, { dispatch }) => {
    try {
        showLoader(true);

        let response = await axios.put('/subject/status/' + id, {
            headers: {
                'Content-Type': 'application/json'
            }
        });
        toast.success(response?.data?.message || 'Status updated successfully.');
        dispatch(getSubject());
        showLoader(false);
        return Promise.resolve(response.data);
    } catch (err) {
        showLoader(false);
        toast.error(err?.response?.data?.message || 'Something went wrong!');
        return Promise.reject(err);
    }
});

export const subjectSlice = createSlice({
    name: 'subjectSlice',
    initialState: {
        data: [],
        option: [],
        selectedData: {}
    },
    extraReducers: (builder) => {
        builder.addCase(getSubject.fulfilled, (state, action) => {
            state.data = action?.payload;
            return;
        });
        builder.addCase(getSubjectOption.fulfilled, (state, action) => {
            state.option = action?.payload;
            return;
        });
        builder.addCase(getSubjectById.fulfilled, (state, action) => {
            state.selectedData = action.payload;
            return;
        });
    }
});

export default subjectSlice.reducer;
